enum ROUTES {
  TOP = "/top",
  LOGIN = "/login",
  REGISTER = "/register",
  DONATIONS = "/donations",
  DONATION_DETAIL = "/donations/:id",
  DONATION_APPLICATION = "/donations/:id/application",
  DONATION_APPLICATION_CONFIRMATION = "/donations/:id/application/confirmation",
  DONATION_APPLICATION_COMPLETE = "/donations/:id/application/complete",
  TRANSACTIONS = "/transactions",
  TRANSACTIONS_DETAIL = "/transactions/:id/detail",
  TRANSACTIONS_EDIT = "/transactions/:id/edit",
  TRANSACTIONS_EDIT_COMPLETE = "/transactions/:id/edit/complete",
  MESSAGE = "/transactions/:id/message",
  CATEGORIES = "/categories",
  SETTINGS = "/settings",
  PROFILE = "/settings/profile",
  ADD_CHILD = "/settings/children-list/add-child",
  EDIT_CHILD = "/settings/children-list/edit-child/:id",
  REMOVE_CHILD = "/settings/remove-child/:id",
  ACCOUNT_NOTIFICATION_COMPLETED = "/settings/children-list/account-notification-completed",
  CHILDREN_LIST = "/settings/children-list",
  CHANGE_EMAIL = "/settings/change-email",
  CHANGE_EMAIL_COMPLETE = "/settings/change-email/complete",
  DELETE_ACCOUNT = "/settings/remove-account",
  CHANGE_PASSWORD_MYPAGE = "/settings/change-password",
  CHANGE_PASSWORD = "/change-password",
  RESET_PASSWORD = "/reset-password",
  PERSONAL_IDENTIFICATION = "/personal-identification",
  LIKES = "/likes",
}

export enum EXTRANAL_ROUTES {
  ABOUT_TODOKUN = "http://www.b4s.jp/todokun",
  FAQ = "http://www.b4s.jp/todokun/faq",
  TOS = "http://www.b4s.jp/todokun/terms",
  PRIVACY_POLICY = "https://www.b4s.jp/privacy/",
  DONATION = "https://b4s.jp/todokun/donate",
  CONTACT = "http://www.b4s.jp/todokun/contact",
}

export default ROUTES;
